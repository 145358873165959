.new-window-container {
    width: max-content
}

@page labelPrintPage {
    size: 20mm 80mm;
    margin: 0.5mm;

}

@media print {
    @page {
        size: 20mm 80mm; /* landscape */
        /* you can also specify margins here: */
        margin: 0.5mm;
    }
}



